.react-datepicker__input-container > input {
    border-radius: 2px;
    padding-left: 19px !important;
    font-family: Montserrat-Bold !important;
    font-size: 12px !important;
    height: 46px !important;
    width: 361px !important;
}

@media (max-width: 480px) {
    .react-datepicker__input-container > input {
        border-radius: 2px;
        padding-left: 19px !important;
        font-family: Montserrat-Bold !important;
        font-size: 12px !important;
        height: 46px !important;
        width: 210px !important;
    }
}

.react-datepicker-ignore-onclickoutside {
    background-color: #3e82cf;
}

.light-picker {
    background-color: #f9f9fb;
    color: inherit;
}

.dark-picker {
    background-color: #272c3d;
    color: #b3a7cb;
}
