.nav-button-text {
    color: #666666;
}

.nav-button-text-h {
    color: inherit;
}

.nav-button {
    background-image: linear-gradient(90deg, #fff, #fff);
    box-shadow: 0px 2px 10px 0px rgba(5, 54, 236, 0);
    border: none;
}

.nav-button-h {
    background-image: linear-gradient(90deg, #168cef, #0227ec);
    box-shadow: 0px 2px 10px 0px rgba(5, 54, 236, 0.49);
    border-radius: 10px;
}
